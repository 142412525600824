import { createSelector } from '@reduxjs/toolkit';
import { Optional } from '../../../../lib/types/Optional';
import { IState } from '../../state-management/interfaces/IState';
import { IPermission, IUserInfo } from '../interfaces/Authentication.types';
import { CUSTOMER_GROUP_PREFIX } from '../constants';
import { IAuthenticationTokens } from '../interfaces/IAuthenticationTokens';

export const selectIsCheckingAuthentication = (state: IState): boolean =>
  state['cross-cutting-concerns']?.authentication?.isCheckingAuthentication;
export const selectAuthenticationTokens = (state: IState): Optional<IAuthenticationTokens> =>
  state['cross-cutting-concerns']?.authentication?.tokens;
export const selectIsLoggedIn = (state: IState): boolean => state['cross-cutting-concerns']?.authentication?.isLoggedIn;
export const selectUserInfo = (state: IState): IUserInfo => state['cross-cutting-concerns']?.authentication?.userInfo;
export const selectPermissions = (state: IState): IPermission[] =>
  state['cross-cutting-concerns']?.authentication?.permissions;
export const selectIsClassificationAvailabilityConfigInitialized = (state: IState): boolean =>
  state['cross-cutting-concerns']?.authentication?.classificationAvailabilityConfig.isInitialized ?? false;
export const selectHasAccessToGCD = (state: IState): boolean =>
  state['cross-cutting-concerns']?.authentication?.classificationAvailabilityConfig.config.hasAccessToGCD ?? false;
export const selectHasAccessToRobots = (state: IState): boolean =>
  state['cross-cutting-concerns']?.authentication?.classificationAvailabilityConfig.config.hasAccessToRobots ?? false;

// https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
export const selectUserGroups = createSelector(
  [selectAuthenticationTokens],
  tokens => tokens?.decoded.idToken['cognito:groups'] ?? []
);

export const selectCustomerId = createSelector(
  [selectAuthenticationTokens],
  tokens =>
    tokens?.decoded.idToken['cognito:groups']
      .find(group => group.startsWith(CUSTOMER_GROUP_PREFIX))
      ?.replace(CUSTOMER_GROUP_PREFIX, '') ?? null
);

export const selectIsAuthorizedFromSupportPortal = (state: IState): boolean | undefined =>
  state['cross-cutting-concerns']?.authentication?.isAuthorizedFromSupportPortal;

export const selectUserCountryCode = createSelector(
  [selectAuthenticationTokens],
  tokens => tokens?.decoded.idToken?.['custom:country'] ?? 'US'
);

export const selectUserId = createSelector(
  [selectAuthenticationTokens],
  tokens => tokens?.decoded.idToken?.identities[0]?.userId ?? ''
);
