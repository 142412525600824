import { Optional } from '../../../../lib/types/Optional';

export interface IAnalyticsDigitalData {
  application: IAnalyticsApplication;
  page: IAnalyticsPage;
  product: IAnalyticsProduct;
  link: IAnalyticsLink;
  attribute: IAnalyticsAttribute;
}

export interface IAnalyticsApplication {
  applicationInfo: {
    namespace: string;
    platform: string;
    country: string;
  };
}

export interface IAnalyticsPage {
  pageInfo: IAnalyticsPageInfo;
}

export interface IAnalyticsPageInfo {
  pageName: Optional<string>;
  pathPattern: Optional<string>;
  pageId: Optional<number>;
}

export interface IAnalyticsProduct {
  productInfo: IAnalyticsProductInfo;
}

export interface IAnalyticsProductInfo {
  productName: Optional<string>;
  productMaterialNo: Optional<string>;
  productSerialNo: Optional<string>;
}

export interface IAnalyticsAttribute {
  filter: IAnalyticsFilter[];
  search: IAnalyticsSearch;
  form: IAnalyticsForm;
  customer: {
    id: Optional<string>;
  };
  machines: Partial<IAnalyticsMachineAttributes>;
  sites: Partial<IAnalyticsSiteAttributes>;
  users: Partial<IAnalyticsUserAttributes>;
}

export interface IAnalyticsMachineAttributes {
  count: Optional<number>;
}

export interface IAnalyticsSiteAttributes {
  count: Optional<number>;
}

export interface IAnalyticsUserAttributes {
  countAuthorized: Optional<number>;
  countOutstandingInvitations: Optional<number>;
}

export enum AnalyticsOverviewFilter {
  DATE_RANGE = 'ov_daterange',
}

export enum AnalyticsMachineListFilter {
  TYPE = 'ma_Machine-Type',
  MATERIAL_NUMBER = 'ma_Material_NO',
  ASSIGNED_SITES = 'ma_Assigned_Objects',
  STATUS = 'ma_Machine_Status',
  SEARCH = 'ma_Search_Entry',
}

export enum AnalyticsMachineDetailsFilter {
  DATE_RANGE = 'md_Daterange',
}

export enum AnalyticsSiteListFilter {
  SEARCH = 'ob_Search_Entry',
  IDS = 'ob_Ids_Entry',
  TAGS = 'ob_Tags_Entry',
}

export enum AnalyticsCleaningReportListFilter {
  TIME_RANGE = 'cr_Timerange',
  ASSIGNED_SITES = 'cr_Assigned_Objects',
  MACHINES = 'cr_Machines',
  STATUS = 'cr_Machine_Status',
  TOTAL_REPORT = 'cr_Total_Report',
  EVENT_REPORT = 'cr_Event_Report',
  GCD = 'cr_Gcd_Report',
  ROBOT = 'cr_Robot_Report',
}
export enum AnalyticsNotificationListFilter {
  ASSIGNED_SITES = 'no_Assigned_Objects',
  MACHINES = 'no_Machines',
}

export type IAnalyticsFilter =
  | AnalyticsOverviewFilter
  | AnalyticsMachineListFilter
  | AnalyticsMachineDetailsFilter
  | AnalyticsSiteListFilter
  | AnalyticsCleaningReportListFilter
  | AnalyticsNotificationListFilter;

export interface IAnalyticsSearch {
  term: Optional<string>;
}

export interface IAnalyticsForm {
  name: Optional<string>;
  fields: Optional<Record<string, any>>;
}

export enum AnalyticsForm {
  EDIT_MACHINE_NAME = 'Edit_Machine_Name',
  ADD_MACHINE_ATTACHMENTS = 'Add_Machine_Attachments',
  ADD_REMINDER = 'Add_Reminder',
  DELETE_REMINDER = 'Delete_Reminder',
  DELETE_MACHINE_ATTACHMENTS = 'Delete_Machine_Attachments',
  ADD_NOTE = 'Add_Note',
  EDIT_NOTE = 'Edit_Note',
  DELETE_NOTE = 'Delete_Note',
  CREATE_SITE = 'Create_Site',
  DELETE_SITE = 'Delete_Site',
  EDIT_SITE_NAME = 'Edit_Site_Name',
  EDIT_SITE_TAGS = 'Edit_Site_Tags',
  EDIT_SITE_LOCATION = 'Edit_Site_Location',
  CHANGE_GEOFENCE = 'Change_Geofence',
  ASSIGN_MACHINE = 'Assign_Machine',
  ADD_WORK_INTERVAL = 'Add_Work_Interval',
  DELETE_WORK_INTERVAL = 'Delete_Work_Interval',
  ASSIGN_MANAGER = 'Assign_Manager',
  CHANGE_REPORT_SUBSCRIPTION = 'Change_Report_Subscription',
  CHANGE_NOTIFICATION_SUBSCRIPTION = 'Change_Notification_Subscription',
  UPSERT_OPERATOR = 'Upsert_Operator',
  EDIT_INFO_MACHINE = 'Edit_Info_Machine',
  INSERT_NOTIFICATION_DETAILS = 'Insert_Notification_Details',
  DELETE_USER = 'Delete_User',
}

export interface IAnalyticsLink {
  linkName: Optional<string>;
}

export interface IAnalyticsTimeRange {
  from: Optional<string>;
  to: Optional<string>;
}

export enum AnalyticsLink {
  LOGIN = 'Login',
  HEADER_LOGO = 'Header:Logo',
  LOGOUT = 'Logout',
  MACHINE_LIST_OPEN_DETAILS = 'Machine_List:Open_Details',
  MACHINE_LIST_MACHINE_TYPE_FILTER = 'Machine_List:Machine_Type',
  MACHINE_LIST_MATERIAL_NO_FILTER = 'Machine_List:Material_Number',
  MACHINE_LIST_ASSIGNED_SITE_FILTER = 'Machine_List:Assigned_Site',
  MACHINE_LIST_MACHINE_STATUS_FILTER = 'Machine_List:Machine_Status',
  MACHINE_LIST_SEARCH_TERM = 'Machine_List:Search_Term',
  MACHINE_DETAILS_OPERATING_HOURS_FILTER = 'Machine_Details:Operating_Hours',
  MACHINE_DETAILS_NOTES_ADD = 'Machine_Details:Notes_Add',
  MACHINE_DETAILS_NOTES_EDIT = 'Machine_Details:Notes_Edit',
  MACHINE_DETAILS_NOTES_DELETE = 'Machine_Details:Notes_Delete',
  MACHINE_DETAILS_EDIT_INFO = 'Machine_Details:Edit_Info',
  ADD_REMINDER = 'Add_Reminder',
  DELETE_REMINDER = 'Delete_Reminder',
  DELETE_MACHINE_ATTACHMENTS = 'Delete_Machine_Attachments',
  SITE_LIST_OPEN_DETAILS = 'Site_List:Open_Details',
  SITE_LIST_TAGS_FILTER = 'Site_List:Tags',
  CREATE_SITE = 'Create_Site',
  DELETE_SITE = 'Delete_Site',
  CHANGE_GEOFENCE = 'Change_Geofence',
  ASSIGN_NEW_MACHINE = 'Assign_New_Machine',
  ADD_WORK_INTERVAL = 'Add_Work_Interval',
  DELETE_WORK_INTERVAL = 'Delete_Work_Interval',
  ASSIGN_MANAGER = 'Assign_Manager',
  UNASSIGN_MANAGER = 'Unassign_Manager',
  CLEANING_REPORTS_TOTAL = 'Cleaning_Reports:Total',
  CLEANING_REPORTS_TOTAL__OPEN_MACHINE_DETAILS = 'Cleaning_Reports_List_Total:Open_Machine_Details',
  CLEANING_REPORTS_TOTAL__OPEN_SITE_DETAILS = 'Cleaning_Reports_List_Total:Open_Site_Details',
  CLEANING_REPORTS_EXACT = 'Cleaning_Reports:Exact',
  CLEANING_REPORTS_EXACT__OPEN_MACHINE_DETAILS = 'Cleaning_Reports_List_Exact:Open_Machine_Details',
  CLEANING_REPORTS_EXACT__OPEN_SITE_DETAILS = 'Cleaning_Reports_List_Exact:Open_Site_Details',
  CLEANING_REPORTS_PERIOD_FILTER = 'Cleaning_Reports:Period',
  CLEANING_REPORTS_ASSIGNED_SITE_FILTER = 'Cleaning_Reports:Assigned_Site',
  CLEANING_REPORTS_MACHINES_FILTER = 'Cleaning_Reports:Machines',
  CLEANING_REPORTS_MACHINE_STATUS_FILTER = 'Cleaning_Reports:Machine_Status',
  CLEANING_REPORTS_TASK_COMPLETION_FILTER = 'Cleaning_Reports:Task_Completion',
  CLEANING_REPORTS_TASK_SEARCH_TERM = 'Cleaning_Reports:Search_Term',
  CLEANING_REPORTS_EXPORT = 'Cleaning_Reports:Export',
  CLEANING_REPORTS_EXPORT_ROBOT_DETAILS = 'Cleaning_Reports:Export_Robot_Details',
  CLEANING_REPORTS_GCD = 'Cleaning_Reports:Gcd',
  CLEANING_REPORTS_ROBOT = 'Cleaning_Reports:Robot',
  CLEANING_REPORTS_ROBOT__OPEN_MACHINE_DETAILS = 'Cleaning_Reports_List_Robot:Open_Machine_Details',
  CLEANING_REPORTS_ROBOT__OPEN_SITE_DETAILS = 'Cleaning_Reports_List_Robot:Open_Site_Details',
  CLEANING_REPORTS_ROBOT__OPEN_ROUTE_IMAGE = 'Cleaning_Reports_List_Robot:Open_Route_Image',
  CLEANING_REPORTS_ROBOT___OPEN_DETAILS = 'Cleaning_Reports_List_Robot:Open_Details',
  REPORT_SUBSCRIPTION_CHANGED = 'Report_Subscription_Changed',
  NOTIFICATIONS_SEVERITY_FILTER = 'Notifications:Severity',
  NOTIFICATIONS_TYPE_FILTER = 'Notifications:Type',
  NOTIFICATIONS_CATEGORIES_FILTER = 'Notifications:Categories',
  NOTIFICATIONS_ASSIGNED_SITE_FILTER = 'Notifications:Assigned_Site',
  NOTIFICATIONS_MACHINES_FILTER = 'Notifications:Machines',
  NOTIFICATION_SUBSCRIPTION_CHANGED = 'Notification_Subscription_Changed',
  PERMISSIONS_PERIOD_FILTER = 'Permissions:Period',
  ASSIGN_ADMINISTRATOR_ROLE = 'Assign_Administrator_Role',
  ASSIGN_STRATEGIC_MANAGER_ROLE = 'Assign_Strategic_Manager_Role',
  INVITE_USER = 'Invite_User',
  OPERATOR_ASSIGNED_MACHINES = 'Operator:Assigned_Machines',
  DELETE_OPERATOR = 'Delete_Operator',
  LIST_OPERATORS = 'List_Operators',
  LIST_SYSTEM_USERS = 'List_System_Users',
  UPSERT_OPERATOR = 'Upsert_Operator',
  CLEANING_REPORTS_ROBOT_DETAILS_REDIRECTED_TO_EVENTS = 'Robot_Details:Redirected_To_Events',
  ROBOT_DETAILS_GET_ROUTE_IMAGE = 'Robot_Details:Get_Route_Image',
}
