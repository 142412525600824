import { combineReducers, Reducer } from 'redux';
import { cleaningReportListReducer } from '../../modules/cleaning/cleaning-report-list/state/cleaningReportListReducer';
import { fooReducer } from '../../modules/foo-management/state/fooReducer';
import { machineDetailsPanelReducer } from '../../modules/machine-inventory/machine-details-panel/state/machineDetailsPanelReducer';
import { robotListReducer } from '../../modules/machine-inventory/robot-list/state/robotListReducer';
import { analyticsReducer } from '../analytics/state/analyticsSlice';
import { authenticationReducer } from '../authentication/state/authenticationReducer';
import { featureFlagReducer } from '../feature-flags/state/featureFlagSlice';
import { translationsReducer } from '../translations/state/translationsSlice';
import { DrawersReducer } from '../drawers/state/drawersSlice';
import { notificationListReducer } from 'app/modules/notification/notification-list/state/notificationListReducer';
import { machineListReducer } from 'app/modules/machine-inventory/machine-list/state/machineListReducer';
import { siteListReducer } from 'app/modules/site-management/site-list/state/siteListReducer';
import { machineDetailsReducer } from 'app/modules/machine-inventory/machine-details/state/machineDetailsReducer';
import { siteDetailsReducer } from 'app/modules/site-management/site-details/state/siteDetailsReducer';
import { siteModalsReducer } from 'app/modules/site-management/modals/state/siteModalsReducer';
import { userListReducer } from 'app/modules/user-management/user-list/state/userListReducer';
import { priorityNotificationsReducer } from 'app/modules/notification/widgets/priority-notifications/state/priorityNotificationsReducer';
import { siteWidgetsFleetDashboardReducer } from 'app/modules/site-management/widgets/fleet-dashboard/state/siteWidgetsFleetDashboardReducer';
import { cleaningWidgetsFleetDashboardReducer } from 'app/modules/cleaning/widgets/fleet-dashboard/state/state/cleaningWidgetsFleetDashboardReducer';
import { fleetOperatingTimeReducer } from 'app/modules/cleaning/widgets/fleet-dashboard/state/FleetOperatingHoursChart/fleetOperatingTimeReducer';
import { userModalsReducer } from 'app/modules/user-management/modals/state/userModalsReducer';
import { notificationModalReducer } from 'app/modules/notification/modals/state/notificationModalsReducer';
import { reminderReducer } from 'app/modules/reminder/state/reminderReducer';
import { reminderModalsReducer } from 'app/modules/reminder/modals/state/reminderModalsReducer';
import { termsConditionsModalsReducer } from 'app/modules/terms-conditions/modals/state/termsConditionsModalsReducer';
import { termsConditionsReducer } from 'app/modules/terms-conditions/state/termsConditionsReducer';
import { machineModalsReducer } from 'app/modules/machine-inventory/modals/state/machineModalsSlice';
import { robotDashboardReducer } from 'app/modules/cleaning/widgets/robot-dashboard/state/RobotDashboardSlice';
import { machineDetailsRobotReducer } from 'app/modules/machine-inventory/machine-details/state/machineDetailsRobotSlice';
import { robotModalsReducer } from 'app/modules/cleaning/widgets/robot-dashboard/modals/state/RobotModalsSlice';
import { noteModalsReducer } from 'app/modules/note/modals/state/noteModalsSlice';
import { noteReducer } from 'app/modules/note/state/noteSlice';
import { machineDetailsPanelRobotReducer } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { siteDetailsPanelReducer } from 'app/modules/site-management/site-details-panel/state/siteDetailsPanelReducer';
import { vncModalReducer } from 'app/components/Vnc/modals/state/vncModalSlice';
import { vncReducer } from 'app/components/Vnc/state/vncSlice';
import { notificationsSubscriptionsReducer } from 'app/modules/notification/NotificationsSubscriptionsDrawer/state/notificationsSubscriptionsSlice';
import { userModalReducer } from 'app/modules/user-management/modals/state/userModalsSlice';

const vncReducers = combineReducers({
  vnc: vncReducer,
  modals: vncModalReducer,
});

export const crossCuttingConcernsReducers = combineReducers({
  analytics: analyticsReducer,
  authentication: authenticationReducer,
  'feature-flags': featureFlagReducer,
  translations: translationsReducer,
  'vnc-feature': vncReducers,
  drawers: DrawersReducer,
});

const robotDashboardReducers = combineReducers({
  dashboard: robotDashboardReducer,
  modals: robotModalsReducer,
});

const cleaningWidgetsReducers = combineReducers({
  'fleet-dashboard': cleaningWidgetsFleetDashboardReducer,
  'robot-dashboard': robotDashboardReducers,
  'fleet-operating-time': fleetOperatingTimeReducer,
});

const cleaningReducers = combineReducers({
  'cleaning-report-list': cleaningReportListReducer,
  widgets: cleaningWidgetsReducers,
});

const machineInventoryReducers = combineReducers({
  modals: machineModalsReducer,
  'machine-list': machineListReducer,
  'robot-list': robotListReducer,
  'machine-details': machineDetailsReducer,
  'machine-details-panel': machineDetailsPanelReducer,
  'machine-details-robot': machineDetailsRobotReducer,
  'machine-details-panel-robot': machineDetailsPanelRobotReducer,
});

const siteWidgetsReducers = combineReducers({
  'fleet-dashboard': siteWidgetsFleetDashboardReducer,
});

const siteManagementReducers = combineReducers({
  modals: siteModalsReducer,
  'site-list': siteListReducer,
  'site-details': siteDetailsReducer,
  'site-details-panel': siteDetailsPanelReducer,
  widgets: siteWidgetsReducers,
});

const userManagementReducers = combineReducers({
  modals: userModalsReducer,
  'user-list': userListReducer,
  modal: userModalReducer,
});

const notificationReducers = combineReducers({
  modals: notificationModalReducer,
  'notification-list': notificationListReducer,
  'notification-subscriptions': notificationsSubscriptionsReducer,
});

const overviewReducers = combineReducers({
  'priority-notifications': priorityNotificationsReducer,
});

const reminderManagementReducers = combineReducers({
  modals: reminderModalsReducer,
  reminder: reminderReducer,
});
const noteReducers = combineReducers({
  note: noteReducer,
  modals: noteModalsReducer,
});

const termsConditionsReducers = combineReducers({
  modals: termsConditionsModalsReducer,
  termsConditions: termsConditionsReducer,
});

export const modulesReducers = combineReducers({
  cleaning: cleaningReducers,
  'foo-management': fooReducer,
  'machine-inventory': machineInventoryReducers,
  'site-management': siteManagementReducers,
  'user-management': userManagementReducers,
  notification: notificationReducers,
  overview: overviewReducers,
  reminder: reminderManagementReducers,
  note: noteReducers,
  'terms-conditions': termsConditionsReducers,
});

export const reducers: Record<string, Reducer<any, any>> = {
  'cross-cutting-concerns': crossCuttingConcernsReducers,
  modules: modulesReducers,
};
