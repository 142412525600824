import { all, AllEffect } from 'redux-saga/effects';
import { cleaningReportListSaga } from '../../modules/cleaning/cleaning-report-list/state/cleaningReportListSaga';
import { cleaningSaga } from '../../modules/cleaning/state/cleaningSaga';
import { fooSaga } from '../../modules/foo-management/state/fooSaga';
import { machineDetailsPanelSaga } from '../../modules/machine-inventory/machine-details-panel/state/machineDetailsPanelSaga';
import { siteModalsSaga } from '../../modules/site-management/modals/state/siteModalsSaga';
import { authenticationSaga } from '../authentication/state/authenticationSaga';
import { analyticsSaga } from '../analytics/state/analyticsSaga';
import { featureFlagSaga } from '../feature-flags/state/featureFlagSaga';
import { machineDetailsPanelRobotSaga } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSaga';
import { machineListSaga } from 'app/modules/machine-inventory/machine-list/state/machineListSaga';
import { robotListSaga } from 'app/modules/machine-inventory/robot-list/state/robotListSaga';
import { machineDetailsSaga } from 'app/modules/machine-inventory/machine-details/state/machineDetailsSaga';
import { siteListSaga } from 'app/modules/site-management/site-list/state/siteListSaga';
import { siteDetailsSaga } from 'app/modules/site-management/site-details/state/siteDetailsSaga';
import { userListSaga } from 'app/modules/user-management/user-list/state/userListSaga';
import { userModalsSaga } from 'app/modules/user-management/modals/state/userModalsSaga';
import { notificationListSaga } from 'app/modules/notification/notification-list/state/notificationListSaga';
import { priorityNotificationsSaga } from 'app/modules/notification/widgets/priority-notifications/state/priorityNotificationsSaga';
import { siteWidgetsFleetDashboardSaga } from 'app/modules/site-management/widgets/fleet-dashboard/state/siteWidgetsFleetDashboardSaga';
import { cleaningWidgetsFleetDashboardSaga } from 'app/modules/cleaning/widgets/fleet-dashboard/state/state/cleaningWidgetsFleetDashboardSaga';
import { fleetOperatingTimeSaga } from 'app/modules/cleaning/widgets/fleet-dashboard/state/FleetOperatingHoursChart/fleetOperatingTimeSaga';
import { notificationModalsSaga } from 'app/modules/notification/modals/state/notificationModalsSaga';
import { reminderSaga } from 'app/modules/reminder/state/reminderSaga';
import { noteSaga } from 'app/modules/note/state/noteSaga';
import { termsConditionsSaga } from 'app/modules/terms-conditions/state/termsConditionsSaga';
import { machineModalsSaga } from 'app/modules/machine-inventory/modals/state/machineModalsSaga';
import { robotDashboardSaga } from 'app/modules/cleaning/widgets/robot-dashboard/state/RobotDashboardSaga';
import { machineDetailsRobotSaga } from 'app/modules/machine-inventory/machine-details/state/machineDetailsRobotSaga';
import { robotModalsSaga } from 'app/modules/cleaning/widgets/robot-dashboard/modals/state/RobotModalsSaga';
import { siteDetailsPanelSaga } from 'app/modules/site-management/site-details-panel/state/siteDetailsPanelSaga';
import { vncSaga } from 'app/components/Vnc/state/vncSaga';
import { notificationsSubscriptionsSaga } from 'app/modules/notification/NotificationsSubscriptionsDrawer/state/notificationsSubscriptionsSaga';

export function* sagas(): Generator<AllEffect<any>, void> {
  yield all([
    // Cross-cutting concerns
    analyticsSaga(),
    authenticationSaga(),
    // Modules
    fooSaga(),
    machineListSaga(),
    robotListSaga(),
    machineDetailsSaga(),
    machineDetailsPanelSaga(),
    machineDetailsPanelRobotSaga(),
    machineModalsSaga(),
    siteListSaga(),
    cleaningSaga(),
    cleaningReportListSaga(),
    cleaningWidgetsFleetDashboardSaga(),
    siteDetailsSaga(),
    siteDetailsPanelSaga(),
    siteModalsSaga(),
    siteWidgetsFleetDashboardSaga(),
    userListSaga(),
    userModalsSaga(),
    notificationListSaga(),
    notificationModalsSaga(),
    priorityNotificationsSaga(),
    fleetOperatingTimeSaga(),
    reminderSaga(),
    noteSaga(),
    termsConditionsSaga(),
    featureFlagSaga(),
    robotDashboardSaga(),
    machineDetailsRobotSaga(),
    robotModalsSaga(),
    vncSaga(),
    notificationsSubscriptionsSaga(),
  ]);
}
